import firebase from "firebase";
import "firebase/storage";
import "firebase/analytics";

// const firebaseConfig = {
//   apiKey: "AIzaSyCP2zWvpmnIbCLYDM2xf09gVIFHCUv-sGs",
//   authDomain: "project-udaan-iitb.firebaseapp.com",
//   projectId: "project-udaan-iitb",
//   storageBucket: "project-udaan-iitb.appspot.com",
//   messagingSenderId: "773889024336",
//   appId: "1:773889024336:web:9a3a674dcf4513dd23cc55",
//   measurementId: "${config.measurementId}",
// };

const firebaseConfig = {
  apiKey: "AIzaSyBhbq6j0JwxYpQTfQvnMQ8l9eisi2PXmjQ",
  authDomain: "udaan-website.firebaseapp.com",
  projectId: "udaan-website",
  storageBucket: "udaan-website.appspot.com",
  messagingSenderId: "900619498075",
  appId: "1:900619498075:web:1e754e1411b79a1e48457e",
  measurementId: "G-NJ0BFHKP5L"
};

firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
const auth = firebase.auth();
const storage = firebase.storage();
const db = firebase.firestore();

export { auth, storage, db, analytics };
