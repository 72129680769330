import React from "react";
import "./Video.css";
import { useTranslation } from "react-i18next";
import YoutubeEmbed from "./YoutubeEmbed";
function Video() {
  const { t } = useTranslation();
  return (
    <div className="video">
      <div className="videocontainer">
        <div className="videobox">
          <YoutubeEmbed embedId="FTvUuhlflX0" />
        </div>
        <div className="videocontent">
          <p className="video_heading">{t("video_title")}</p>
          {/* <p className="video_subheading">{t("video_subheading")}</p> */}
          <p className="video_description">{t("video_description")}</p>
          <div className="video_buttons">
            <button
              onClick={() => {
                window.open(
                  "./DownloadTool",
                );
              }}
              className="video_button1"
            >
              {t("video_button1")}
            </button>
            <button
              onClick={() => {
                window.open(
                  './UDAANVideos',
                  // "_blank"
                );
              }}
              className="video_button2"
            >
              {t("video_button2")}
            </button>
            <button
              onClick={() => {
                window.open(
                  "https://arxiv.org/pdf/2203.01644",
                  "_blank"
                );
              }}
              className="video_button2"
            >
              {t("Read Paper")}
            </button>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default Video;
