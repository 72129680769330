import React from "react";
import Footer from "../Footer/Footer";
import { useTranslation } from "react-i18next";

import "../LandingBanner/LandingBanner.css";
import "./InstallationGuide.css";
import ubuntu from "../../../Images/News/ubuntu.jpg";
import windows from "../../../Images/News/windows.webp";

function DownloadTool() {
    const { t } = useTranslation();
      
    return (
    <>
        <div className="landingbanner">
            <div className="banner_box">
                <p className="banner_heading">{t("banner_title")}</p>
                <p className="banner_subtitle">"{t("banner_subheading")}"</p>
                {/* <div className="banner_subtitle2">
                <p>{t("banner_subheading2")}</p>
                <a href="https://acr.iitb.ac.in/donation/" target="_blank" rel="noreferrer">
                    <p className="banner_orange">{t("banner_subheading3")}</p>
                </a>
                </div> */}
            </div>
        </div>
        
        <div className="container" style={{textAlign:'center'}}>
            <h4>Choose the OS for downloading the Tool.</h4>
            <a href="https://www.cse.iitb.ac.in/~ayusham/Udaan-Windows-v4.0.zip" target="_blank">
                <div className="box1_container">
                    <div className="box1"><img src={windows} /></div>
                    <h4>Windows</h4>
                </div>
            </a>
            <a href="https://www.cse.iitb.ac.in/~ayusham/Udaan-Linux-v4.0.tar.xz" target="_blank">
                <div className="box1_container">
                    <div className="box2"><img src={ubuntu}/></div>
                    <h4>Ubuntu</h4>
                </div>
            </a>
        </div>

        <Footer />
    </>
  );
}

export default DownloadTool;
