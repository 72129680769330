import React from "react";
import "./Footer.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-scroll";
function Footer() {
  const { t } = useTranslation();
  return (
    <div className="Footer">
      <div className="footertop">
        <div className="footertopleft">
          <div className="footertopleftsection1">
            {/* <p className="footertopleftsection1heading">
              {t("footer_left_section1_heading")}
            </p>
            <Link
              activeClass="active"
              spy={true}
              smooth={true}
              className="active navItems"
              to="About"
            >
              <p className="footertopleftsection1item">
                {t("footer_left_section1_item1")}
              </p>
            </Link>
            <Link to="Team" spy={true} smooth={true} className="navItems">
              <p className="footertopleftsection1item">
                {t("footer_left_section1_item2")}
              </p>
            </Link>
            <Link to="Projects" spy={true} smooth={true} className="navItems">
              <p className="footertopleftsection1item">
                {t("footer_left_section1_item3")}
              </p>
            </Link>
            <Link
              to="Publications"
              spy={true}
              smooth={true}
              className="navItems"
            >
              <p className="footertopleftsection1item">
                {t("footer_left_section1_item4")}
              </p>
            </Link> */}
            <div className="footertopleftsection1bottom">
              <p className="footertopleftseaction1bottom">
                {t("footer_left_section1_footer1")}
              </p>
              <p className="footertopleftseaction1bottom2">
                {t("footer_left_section1_footer2")}
              </p>
            </div>
          </div>
          {/* <div className="footertopleftsection2">
            <p className="footertopleftsection2heading">
              {t("footer_left_section2_heading")}
            </p>
            <Link to="Contact" spy={true} smooth={true} className="navItems">
              <p className="footertopleftsection2item">
                {t("footer_left_section2_item1")}
              </p>
            </Link>
            <Link to="Donate" spy={true} smooth={true} className="navItems">
              <p className="footertopleftsection2item">
                {t("footer_left_section2_item2")}
              </p>
            </Link>
          </div> */}
        </div>
        <div className="footertopright">
          <div className="footertoprightbox1">
            <p className="footertoprightheading">
              {t("footer_right_section1_heading")}
            </p>
            <div className="footertoprightbox1item">
            
              <svg
              onClick={() => {
                window.open(
                  "https://twitter.com/udaan_project",
                  "_blank"
                );
              }}
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M37.4322 0.208984H4.49186C2.01198 0.208984 0 2.20835 0 4.67268V37.4065C0 39.8708 2.01198 41.8702 4.49186 41.8702H37.4322C39.9121 41.8702 41.924 39.8708 41.924 37.4065V4.67268C41.924 2.20835 39.9121 0.208984 37.4322 0.208984ZM32.8561 14.9764C32.8748 15.2368 32.8748 15.5065 32.8748 15.7668C32.8748 23.8294 26.6985 33.1195 15.4127 33.1195C11.9315 33.1195 8.70298 32.1151 5.98915 30.3855C6.48513 30.4413 6.96239 30.4599 7.46772 30.4599C10.3406 30.4599 12.9796 29.4927 15.0852 27.856C12.3901 27.8002 10.1254 26.0426 9.34869 23.6248C10.2939 23.7643 11.1454 23.7643 12.1187 23.5132C9.31126 22.946 7.2057 20.4909 7.2057 17.5244V17.45C8.01985 17.9057 8.97437 18.1847 9.97568 18.2219C9.13385 17.6654 8.44375 16.9105 7.96695 16.0246C7.49014 15.1386 7.24147 14.1491 7.24313 13.1444C7.24313 12.0099 7.54259 10.9684 8.07599 10.0663C11.0986 13.7675 15.6373 16.1853 20.7281 16.4457C19.8578 12.3075 22.974 8.9504 26.7172 8.9504C28.4859 8.9504 30.0768 9.68505 31.1997 10.8754C32.5847 10.615 33.9136 10.1035 35.0927 9.40607C34.6341 10.8196 33.6703 12.0099 32.3976 12.7631C33.6328 12.6329 34.8307 12.2889 35.9349 11.8146C35.102 13.0328 34.0539 14.1116 32.8561 14.9764Z"
                  fill="#DFDFDF"
                />
              </svg>
              <svg
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/channel/UClfK7iC8J7b22bj3GwAUaCw",
                    "_blank"
                  );
                }}
                width="42"
                height="42"
                viewBox="0 0 42 42"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.441 16.0272L26.3296 21.0582L17.441 26.0891V16.0272ZM41.8286 4.67268V37.4065C41.8286 39.8708 39.8212 41.8702 37.3469 41.8702H4.48163C2.0074 41.8702 0 39.8708 0 37.4065V4.67268C0 2.20835 2.0074 0.208984 4.48163 0.208984H37.3469C39.8212 0.208984 41.8286 2.20835 41.8286 4.67268ZM37.9071 21.0675C37.9071 21.0675 37.9071 15.5251 37.1976 12.8654C36.8054 11.3961 35.657 10.243 34.1911 9.85244C31.5488 9.13638 20.9143 9.13638 20.9143 9.13638C20.9143 9.13638 10.2797 9.13638 7.63745 9.85244C6.17158 10.243 5.02316 11.3961 4.63102 12.8654C3.92143 15.5158 3.92143 21.0675 3.92143 21.0675C3.92143 21.0675 3.92143 26.6099 4.63102 29.2695C5.02316 30.7388 6.17158 31.8455 7.63745 32.236C10.2797 32.9428 20.9143 32.9428 20.9143 32.9428C20.9143 32.9428 31.5488 32.9428 34.1911 32.2267C35.657 31.8362 36.8054 30.7295 37.1976 29.2602C37.9071 26.6099 37.9071 21.0675 37.9071 21.0675Z"
                  fill="#DFDFDF"
                />
              </svg>
            </div>
          </div>
         
        </div>
      </div>
      <div className="footerbottom">
      {/* <div className="footerbottomtext"> */}
            {/* {t("footer_right_section2_bottom1")} */}
            {/* <a href="https://acr.iitb.ac.in/donation/" target="_blank" rel="noreferrer">
              <p className="footertoprightbox2specialcolor">
                {t("footer_right_section2_bottom2")}
              </p>
            </a>
          <br/> */}
        <p className="footerbottomtext">{t("footer_bottom1")}</p>
        <p className="footerbottomtext">{t("footer_bottom2")}</p>
      </div>
    </div>
  );
}

export default Footer;
