import React from "react";
import "./Events.css";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import SwiperCore, { Navigation } from "swiper";
import "swiper/components/navigation/navigation.scss";
// import img1 from "../../../Images/Events/1.png";
import YoutubeEmbed from "../Video Section/YoutubeEmbed";

import { EffectCoverflow, Pagination } from "swiper";

SwiperCore.use([EffectCoverflow, Pagination]);
// SwiperCore.use([Navigation]);
function Events() {
  const navigationPrevRef = React.useRef(null)
  const navigationNextRef = React.useRef(null)
  const { t } = useTranslation();
  return (
    
    <div className="Events">
      <div>
        <p className="event_title">{t("event_title")}</p>
        <Swiper  
         navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        loop={true}
        autoplay={{
            delay: 5000,
            disableOnInteraction: true
        }}
       effect={"coverflow"}
       grabCursor={true}
       centeredSlides={true}
       slidesPerView={"auto"}
       coverflowEffect={{
         rotate: 50,
         stretch: 0,
         depth: 50,
         modifier: 1,
         slideShadows: false,
       }}
        pagination={true}
        className="mySwiper">
          <SwiperSlide>
            <div className="events_slider">
              <div className="events_left">
                <p className="events_project_title">
                  {t("events_project1_title")}
                </p>
                <p className="events_project_description">
                  {t("events_project1_description")}
                </p>
              </div>
              <div className="events_right">
                <YoutubeEmbed embedId="R9fLAgkUGH8" />
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="events_slider">
              <div className="events_left">
                <p className="events_project_title">
                President of India released Technical Books of Diploma & Engineering in Malayalam
                </p>
                <p className="events_project_description">
                Dedication of AICTE Technical books of Diploma and Engineering in Malayalam to the Nation by Honourable President of India Smt. Droupadi Murmu. Malayalam translation was completed with the help of UDAAN Project.
                </p>
              </div>
              <div className="events_right">
                <YoutubeEmbed embedId="HmFhs8ksajo" />
              </div>
            </div>
          </SwiperSlide>
          
          <SwiperSlide>
            <div className="events_slider">
              <div className="events_left">
                <p className="events_project_title">
                Launch video for the release of Diploma & Engineering in Malayalam
                </p>
                <p className="events_project_description">
                Launch video for the release of Diploma & Engineering in Malayalam released by Honourable President of India Smt. Droupadi Murmu. Malayalam translation was completed with the help of UDAAN Project.
                </p>
              </div>
              <div className="events_right">
                <YoutubeEmbed embedId="oQ-HZMNil3o" />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
        
      </div>
    </div>
  );
}

export default Events;
