import React from "react";
import "./RecentNews.css";
import Card from 'react-bootstrap/Card';
import { textAlign } from "@mui/system";

function RecentNews() {
    const news = [
        ["CSTT credits IIT Bombay Udaan project for OCR of printed glossaries.","05 Mar 2024","https://shabd.education.gov.in/kosha/credits.jsp"],
        ["6-10वीं कक्षा के छात्रों की शिक्षा क्षमता को बढ़ाने वाला IIT बॉम्बे का प्रोजेक्ट उड़ान", "14 Sep 2023", ""],
        ["Prof. Pushpak Bhattacharyya addressed Project Udaan", " 30 Sep 2023", "https://youtu.be/Ch0pqe-gxg0"],
        ["The CSTT Chairman, Prof. Girishnath Jha, briefed Project Udaan", " 30 Sep 2023","https://www.youtube.com/watch?v=cQ7beJBY1XA"],
        ["UDAAN Project signs an MoU with CSTT to prepare and increase usage of scientific and technical terms into multiple Indian languages and domains.", "27 Jul 2023", "https://www.iitb.ac.in/en/breaking-news/iit-bombay-and-cstt-sign-mou-project-udaan"],
        ["Technology powering UDAAN Project is licensed to BBSTech", "17 Apr 2023", "https://bharatbhasha.tech/"], 
        ["First batch of engineering books translated in Malayalam using our post-editing tool was released by Honourable President of India in Thiruvananthapuram, Kerala.","17 Mar 2023", "https://www.youtube.com/live/1rqD-u061SU?feature=share&t=3160"],
        ["Mathrubhoomi covered our project used by Digitial University of Kerala to translate books in Malayalam","17 Mar 23","https://www.youtube.com/watch?v=pL7Pd8zaa6Y"],
        ["UDAAN Project receive positive response at National Book Fair, New Delhi", "2 Mar 2023",""],
        ["Prof Ganesh in conversation with NDTV India at Vishwa Hindi Sammelan in Fiji","17 Feb 2023","https://ndtv.in/videos/project-udaan-will-teach-hindi-as-well-as-english-682716"],
        ["Prof Ganesh attended and gave a talk on Project UDAAN at Vishwa Hindi Sammelan, 2023 in Fiji","17 Feb 2023", "https://www.vishwahindisammelan.gov.in/"],
        ["Campus Talk: How a software designed by IIT-B is playing a key role in translating academic books into Indian languages", "11 Jan 2023", "https://indianexpress.com/article/cities/mumbai/campus-talk-project-udaan-by-iit-bombay-proves-a-game-changer-in-translating-textbooks-into-indian-languages-8373028/"],
        ["Driving Change! Know How IIT Bombay & Maharashtra Govt Plans To Break Language Barrier In Education","8 Jan 2023","https://thelogicalindian.com/education/iit-bombay-and-maharashtra-government-signs-mou-for-project-udaan-39732"],
     ["UDAAN team won the best demo paper award at CODS-COMAD 2023 🥳","6 Jan 2023", "https://cods-comad.in/awards.php"],   
    ["In a ceremony in Rajbhavan, Department of Higher Education (Govt of Maharashtra) signed an MoU with UDAAN project to translate conventional and professional books into Marathi", "5 Jan 2023", "https://rajbhavan-maharashtra.gov.in/en/05-01-2023-governor-minister-review-preparedness-of-universities-for-implementing-nep-from-coming-academic-year/"],
    ["AICTE Accepts IIT Bombay’s UDAAN Project For Technical Book Translation - Jagran Josh", "28 Dec 22", "https://www.jagranjosh.com/news/aicte-accepts-iit-bombays-udaan-project-for-technical-book-translation-166262"],
    ["AICTE acknowledges contribution of Udaan Project in translating diploma and engineering books under Technical Book Writing Scheme", "27 Dec 22", "https://www.aicte-india.org/sites/default/files/Acknowledgement%20to%20UDAAN%20TEAM%2C%20IIT%20Bombay%20for%20Technical%20Book%20Writing%20Scheme%20.pdf"],
    ["Provided training to Telugu translators and reviewers ", "20 Dec 22", "https://www.thehindu.com/news/national/andhra-pradesh/jntua-holds-orientation-for-telugu-translators-of-engineering-books/article66289164.ece"],
    ["Provided training to Punjabi translators and reviewers"  , "20 Dec 22", "https://punjabikhabarsaar.com/wp-content/uploads/2022/12/3-min-1.jpeg"],
    ["Provided training to Assamese translators and reviewers", "05 Dec 22", ""],
]
    return (
        <div className="recentNews">
            <div className="recentNewsTitle">
                <p className="recentNews_Title">Latest News</p>
            </div>

            <div id="projectFacts" className="sectionClass">
                {news.map(item => {
                    return <Card body style={{ backgroundColor: "#FFF" }}>
                        <div className="row" style={{ width: "100%" }}>
                            <div className="col-sm-10" style={{width:"85%",textAlign:"left"}}>
                                <a href={item[2]}>{item[0]}</a>
                            </div>
                            <div className="col-sm-2" style={{width:"15%"}}>
                                {item[1]}
                            </div>
                        </div>
                    </Card>;
                })}
            </div>
            {/* <div className="bottomPadding" style={{ padding: "20px" }}></div> */}
        </div>
    );
}

export default RecentNews;