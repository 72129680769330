import React from "react";
import Footer from "../Footer/Footer";
import Glossaries from "./Glossaries";
import RelatedProjects from "../Related Projects/RelatedProjects";

function GlossariesRelatedProjects() {
  return (
    <>
        <Glossaries />
        <Footer />
    </>
  );
}
export default GlossariesRelatedProjects;