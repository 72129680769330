
import { csv } from 'd3-request';
import React, { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select }
    from '@mui/material';
import Button from '@material-ui/core/Button';
import TextField from "@material-ui/core/TextField";
import "./Glossaries.css";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { CSVLink, CSVDownload } from 'react-csv';
// $(document).ready(function () {
//     $('#example').DataTable();
// });
const styles = theme => ({
    formControl: {
        margin: theme.spacing.unit,
        minWidth: 120
    },
    select: {
        "& option": {
            background: theme.palette.background.paper,
            color: '#fff'
        }
    }
});


const Glossaries = () => {
    const [inputField, setInputField] = useState({
        sourceLanguage: '',
        targetLanguage: [],
        term: '',
        domain: [],
        glossValue: '',
        csvData: []
    })
    var csvd = []
    const { sourceLanguage, targetLanguage, domain, csvData } = inputField;
    const inputsHandler = (e) => {
        setInputField({ ...inputField, [e.target.name]: e.target.value })
    }

    const inputsHandlerDomain = (e, b) => {
        const target = e.target;
        // console.log(target)
        var y = ['Administrative', 'Agriculture', 'AgriTerm', 'Biology', 'Biotech', 'Botany', 'Broadcasting', 'CellBio', 'Chemistry', 'ChemEng', 'Civil', 'ClinicalEng', 'Computer Science', 'Electrical', 'Electronics', 'Geography', 'History', 'IT', 'Mathematics', 'MechEng', 'Mech', 'Medical', 'MedicalComp', 'Microbio', 'Mineralogy', 'MiningGeo', 'Nematology', 'OperationRes', 'Palaeontology', 'Petrology', 'Physics', 'PlasmaEngg', 'PrintingEngg', 'Psychology', 'QualityControl', 'Rbi', 'SamajKarya', 'Sericulture', 'ShikshaParibhasha', 'Sociology', 'SpaceSci', 'Telecommunication', 'Vanijya', 'WesternMusic', 'Zoology']

        // console.log(target.value[target.value.length - 1])
        if (target.value[target.value.length - 1] === 'All') {
            if (JSON.stringify(inputField.domain) == JSON.stringify(y)) {
                y = []
            }
            setInputField(inputField => ({ ...inputField, [target.name]: y }));
        } else {
            setInputField(inputField => ({ ...inputField, [target.name]: target.value }));
        }
    };

    const onChange = (e, b) => {
        const target = e.target;
        // console.log(target)
        var y = ['English', 'Hindi', 'Assamese', 'Bengali', 'Gujarati', 'Kannada', 'Malayalam', 'Odiya', 'Punjabi', 'Telugu', 'Tamil']
        // console.log(target.value[target.value.length - 1])
        if (target.value[target.value.length - 1] === 'All') {
            if (JSON.stringify(inputField.targetLanguage) == JSON.stringify(y)) {
                y = []
            }
            setInputField(inputField => ({ ...inputField, [target.name]: y }));
        } else {
            setInputField(inputField => ({ ...inputField, [target.name]: target.value }));
        }
    };

    const submitButton = () => {
        var sourceLang = ''
        var sourceL = ''
        var targetLang = ''
        var targetL = ''
        var domainName = ''
        // console.log(inputField.sourceLanguage);
        sourceL = inputField.sourceLanguage
        switch (inputField.sourceLanguage) {
            case 'English':
                sourceLang = 'en'
                break;
            case 'Hindi':
                sourceLang = 'hi'
                break;
            case 'Bengali':
                sourceLang = 'bn'
                break;
            case 'Gujarati':
                sourceLang = 'gu'
                break;
            case 'Kannada':
                sourceLang = 'kn'
                break;
            case 'Malayalam':
                sourceLang = 'ml'
                break;
            case 'Odiya':
                sourceLang = 'or'
                break;
            case 'Punjabi':
                sourceLang = 'pa'
                break;
            case 'Telugu':
                sourceLang = 'te'
                break;
            case 'Tamil':
                sourceLang = 'ta'
                break;
            case 'Assamese':
                sourceLang = 'as'
                break;
            default:
                sourceLang = ''
        }
        document.getElementById("GlossV").innerHTML = ''
        var HTML = "<table id='example' className='table table-striped table-bordered' border=1 width=70%><thead className='thead-dark'><tr><th scope='row'>Source Language</th>" +
            "<th>Target Language</th><th>Input Term</th> <th>Translation</th> <th>Domain</th> </tr></thead>";
        var RHTML = ''
        var targetLangArr = []
        for (var targetIndex = 0; targetIndex < inputField.targetLanguage.length; targetIndex++) {
            targetL = inputField.targetLanguage[targetIndex]

            switch (inputField.targetLanguage[targetIndex]) {
                case 'English':
                    targetLang = 'en'
                    break;
                case 'Hindi':
                    targetLang = 'hi'
                    break;
                case 'Bengali':
                    targetLang = 'bn'
                    break;
                case 'Gujarati':
                    targetLang = 'gu'
                    break;
                case 'Kannada':
                    targetLang = 'kn'
                    break;
                case 'Malayalam':
                    targetLang = 'ml'
                    break;
                case 'Odiya':
                    targetLang = 'or'
                    break;
                case 'Punjabi':
                    targetLang = 'pa'
                    break;
                case 'Telugu':
                    targetLang = 'te'
                    break;
                case 'Tamil':
                    targetLang = 'ta'
                    break;
                case 'Assamese':
                    targetLang = 'as'
                    break;
                default:
                    targetLang = ''
            }
            targetLangArr.push(targetLang);
        }
        var domain = inputField.domain
        var d = []
        //console.log(inputField.domain)
        for (var i = 0; i < inputField.domain.length; i++) {
            switch (inputField.domain[i]) {
                case 'Administrative':
                    domainName = 'administrative'
                    break;
                case 'Agriculture':
                    domainName = 'agri'
                    break;
                case 'AgriTerm':
                    domainName = 'agri_term'
                    break;
                case 'Biology':
                    domainName = 'bio'
                    break;
                case 'Biotech':
                    domainName = 'biotech'
                    break;
                case 'Botany':
                    domainName = 'botany'
                    break;
                case 'Broadcasting':
                    domainName = 'broadcasting'
                    break;
                case 'CellBio':
                    domainName = 'cell_bio'
                    break;
                case 'Chemistry':
                    domainName = 'chem'
                    break;
                case 'Civil':
                    domainName = 'civil'
                    break;
                case 'ClinicalEng':
                    domainName = 'clinical_eng'
                    break;
                case 'Civil':
                    domainName = 'civil'
                    break;
                case 'Civil':
                    domainName = 'civil'
                    break;
                case 'Computer Science':
                    domainName = 'comp'
                    break;
                case 'Electrical':
                    domainName = 'electrical'
                    break;
                case 'Electronics':
                    domainName = 'electronics'
                    break;
                case 'Geography':
                    domainName = 'geography'
                    break;
                case 'History':
                    domainName = 'history'
                    break;
                case 'IT':
                    domainName = 'it'
                    break;
                case 'Mathematics':
                    domainName = 'math'
                    break;
                case 'MechEng':
                    domainName = 'mech_eng'
                    break;
                case 'Mech':
                    domainName = 'mech'
                    break;
                case 'Medical':
                    domainName = 'med'
                    break;
                case 'MedicalComp':
                    domainName = 'med_comp'
                    break;
                case 'Microbio':
                    domainName = 'microbio'
                    break;
                case 'Mineralogy':
                    domainName = 'mineralogy'
                    break;
                case 'MiningGeo':
                    domainName = 'mining_geo'
                    break;
                case 'Nematology':
                    domainName = 'nematology'
                    break;
                case 'OperationRes':
                    domainName = 'operational_res'
                    break;
                case 'Palaeontology':
                    domainName = 'palaeontology'
                    break;
                case 'Petrology':
                    domainName = 'petrology'
                    break;
                case 'Physics':
                    domainName = 'phy'
                    break;
                case 'PlasmaEngg':
                    domainName = 'plasma_eng'
                    break;
                case 'PrintingEngg':
                    domainName = 'printing_eng'
                    break;
                case 'Psychology':
                    domainName = 'psychology'
                    break;
                case 'QualityControl':
                    domainName = 'quality_cont'
                    break;
                case 'Rbi':
                    domainName = 'rbi'
                    break;
                case 'SamajKarya':
                    domainName = 'samaj_karya'
                    break;
                case 'Sericulture':
                    domainName = 'sericulture'
                    break;
                case 'ShikshaParibhasha':
                    domainName = 'shiksha_paribhasha'
                    break;
                case 'Sociology':
                    domainName = 'sociology'
                    break;
                case 'SpaceSci':
                    domainName = 'space_sci'
                    break;
                case 'Telecommunication':
                    domainName = 'telecomm'
                    break;
                case 'Vanijya':
                    domainName = 'vanijya'
                    break;
                case 'WesternMusic':
                    domainName = 'western_music'
                    break;
                case 'Zoology':
                    domainName = 'zoology'
                    break;
                default:
                    domainName = ''
            }
            domainName = domainName + '_eng_to_indic.csv'
            d.push(domainName)
        }
        //console.log(d)
        var term = inputField.term
        // var filePath = '/glossaries/UniversalGloss.csv'
        // console.log(filePath)
        var glossV = ''
        var match = false
        var prevmatch = false
        var regmatch = false
        csvd.push(['Source Language', 'Target Language', 'Input Language', 'Translation', 'Domain'])
        csv('/glossaries/UniversalGloss1.csv', function (err, data) {

            for (var i = 0; i < data.length; i++) {

                if (d.includes(data[i]['DictionaryName'])) {

                    if (data[i][sourceL].toLowerCase() === term.toLowerCase()) {
                        match = true
                        prevmatch = true
                        for (var targetIndex = 0; targetIndex < inputField.targetLanguage.length; targetIndex++) {
                            glossV = data[i][inputField.targetLanguage[targetIndex]]
                            HTML += "<tr><td align=center scope='row'>" + inputField.sourceLanguage + "</td>" + "<td align=center>" + inputField.targetLanguage[targetIndex] + "</td>" + "<td align=center>" + data[i][sourceL] + "</td>";
                            HTML += "<td align=center>" + glossV + "</td>" + "<td align=center>" + data[i]['DictionaryName'].substring(0, data[i]['DictionaryName'].length - 17) + "</td></tr>";
                            csvd.push([inputField.sourceLanguage, inputField.targetLanguage[targetIndex], data[i][sourceL], glossV, data[i]['DictionaryName'].substring(0, data[i]['DictionaryName'].length - 17)])
                        }

                    }
                    else {
                        if (match == false) {

                            let myReg = new RegExp(term.toLowerCase() + ".*")
                            if (data[i][sourceL].toLowerCase().match(myReg)) {
                                regmatch = true
                                console.log(myReg)
                                for (var targetIndex = 0; targetIndex < inputField.targetLanguage.length; targetIndex++) {
                                    glossV = data[i][inputField.targetLanguage[targetIndex]]
                                    RHTML += "<tr><td align=center scope='row'>" + inputField.sourceLanguage + "</td>" + "<td align=center>" + inputField.targetLanguage[targetIndex] + "</td>" + "<td align=center>" + data[i][sourceL] + "</td>";
                                    RHTML += "<td align=center>" + glossV + "</td>" + "<td align=center>" + data[i]['DictionaryName'].substring(0, data[i]['DictionaryName'].length - 17) + "</td></tr>";
                                    csvd.push([inputField.sourceLanguage, inputField.targetLanguage[targetIndex], data[i][sourceL], glossV, data[i]['DictionaryName'].substring(0, data[i]['DictionaryName'].length - 17)])

                                }
                            }
                            if (regmatch == false) {
                                glossV = 'Value not present'
                            }
                        }

                    }
                }
                if (i == data.length - 1) {
                    if (match == false) {

                        //HTML = HTML + RHTML
                    }
                }
            }

            // console.log(targetIndex)
            if (match == false && regmatch == false) {
                document.getElementById("GlossV").innerHTML = '<h2>Value not present</h2>'
            } else {
                if (targetIndex == inputField.targetLanguage.length) {
                    // HTML += "</table>";
                    document.getElementById("GlossV").innerHTML = HTML;
                }
            }
        });
        csv('/glossaries/UniversalGloss2.csv', function (err, data) {

            for (var i = 0; i < data.length; i++) {

                if (d.includes(data[i]['DictionaryName'])) {

                    if (data[i][sourceL].toLowerCase() === term.toLowerCase()) {
                        match = true
                        for (var targetIndex = 0; targetIndex < inputField.targetLanguage.length; targetIndex++) {
                            glossV = data[i][inputField.targetLanguage[targetIndex]]
                            HTML += "<tr><td align=center scope='row'>" + inputField.sourceLanguage + "</td>" + "<td align=center>" + inputField.targetLanguage[targetIndex] + "</td>" + "<td align=center>" + data[i][sourceL] + "</td>";
                            HTML += "<td align=center>" + glossV + "</td>" + "<td align=center>" + data[i]['DictionaryName'].substring(0, data[i]['DictionaryName'].length - 17) + "</td></tr>";
                            csvd.push([inputField.sourceLanguage, inputField.targetLanguage[targetIndex], data[i][sourceL], glossV, data[i]['DictionaryName'].substring(0, data[i]['DictionaryName'].length - 17)])

                        }

                    }
                    else {
                        if (match == false) {

                            let myReg = new RegExp(term.toLowerCase() + ".*")
                            if (data[i][sourceL].toLowerCase().match(myReg)) {
                                regmatch = true
                                console.log(myReg)
                                for (var targetIndex = 0; targetIndex < inputField.targetLanguage.length; targetIndex++) {
                                    glossV = data[i][inputField.targetLanguage[targetIndex]]
                                    RHTML += "<tr><td align=center scope='row'>" + inputField.sourceLanguage + "</td>" + "<td align=center>" + inputField.targetLanguage[targetIndex] + "</td>" + "<td align=center>" + data[i][sourceL] + "</td>";
                                    RHTML += "<td align=center>" + glossV + "</td>" + "<td align=center>" + data[i]['DictionaryName'].substring(0, data[i]['DictionaryName'].length - 17) + "</td></tr>";
                                    csvd.push([inputField.sourceLanguage, inputField.targetLanguage[targetIndex], data[i][sourceL], glossV, data[i]['DictionaryName'].substring(0, data[i]['DictionaryName'].length - 17)])

                                }
                            }
                            if (regmatch == false) {
                                glossV = 'Value not present'
                            }
                        }
                    }
                }
                if (i == data.length - 1) {
                    if (match == false && prevmatch == false) {
                        HTML = HTML + RHTML
                    }
                }
            }


            // console.log(targetIndex)
            if (match == false && regmatch == false) {
                document.getElementById("GlossV").innerHTML = '<h2>Value not present</h2>'
            } else {
                if (targetIndex == inputField.targetLanguage.length) {
                    HTML += "</table>";
                    console.log(csvd)
                    setInputField(inputField => ({ ...inputField, csvData: csvd }))
                    console.log(inputField.csvData)
                    document.getElementById("GlossV").innerHTML = HTML;
                }
            }
        });
    }

    return (
        <div className="landingbanners-glossary" >
            <CssBaseline />
            <div className="container" style={{ padding:"2%"}}>
                <div><span style={{ color: '#eb522f', fontSize: "40px", fontWeight: "700" }}>Udaan Indic Glossary Explorer</span></div>
                <div><h3>An open source service to store and explore relevant Indic glossary which are domain specific</h3></div>

                <div className="row">
                    <div className="glossary-div">
                        <FormControl variant="outlined">
                            <InputLabel id="standard" style={{ color: '#011633', background: '#FFF' }}>
                                Select Source Language:
                            </InputLabel>
                            <Select
                                style={{ color: '#011633', border: "solid #011633" }}
                                labelId="standards"
                                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                name="sourceLanguage"
                                id="sourceLanguage"
                                value={sourceLanguage}
                                label="Package Type"
                                onChange={onChange}
                            >
                                <MenuItem value={'English'}>English</MenuItem>
                                <MenuItem value={'Hindi'}>Hindi</MenuItem>
                                <MenuItem value={'Assamese'}>Assamese</MenuItem>
                                <MenuItem value={'Bengali'}>Bengali</MenuItem>
                                <MenuItem value={'Gujarati'}>Gujarati</MenuItem>
                                <MenuItem value={'Kannada'}>Kannada</MenuItem>
                                <MenuItem value={'Malayalam'}>Malayalam</MenuItem>
                                <MenuItem value={'Odiya'}>Odiya</MenuItem>
                                <MenuItem value={'Punjabi'}>Punjabi</MenuItem>
                                <MenuItem value={'Telugu'}>Telugu</MenuItem>
                                <MenuItem value={'Tamil'}>Tamil</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="glossary-div">
                        <FormControl>
                            <InputLabel id="demo-simple-select-standard-label" style={{ color: '#011633', background: '#FFF' }}>
                                Select Target Language:
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                name="targetLanguage"
                                multiple={true}
                                id="targetLanguage"
                                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
                                value={targetLanguage}
                                style={{ color: '#011633', border: "solid #011633" }}
                                onChange={onChange}
                            >
                                <MenuItem value={'All'}>Select All</MenuItem>
                                <MenuItem value={'English'}>English</MenuItem>
                                <MenuItem value={'Hindi'}>Hindi</MenuItem>
                                <MenuItem value={'Assamese'}>Assamese</MenuItem>
                                <MenuItem value={'Bengali'}>Bengali</MenuItem>
                                <MenuItem value={'Gujarati'}>Gujarati</MenuItem>
                                <MenuItem value={'Kannada'}>Kannada</MenuItem>
                                <MenuItem value={'Malayalam'}>Malayalam</MenuItem>
                                <MenuItem value={'Odiya'}>Odiya</MenuItem>
                                <MenuItem value={'Punjabi'}>Punjabi</MenuItem>
                                <MenuItem value={'Telugu'}>Telugu</MenuItem>
                                <MenuItem value={'Tamil'}>Tamil</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="glossary-div">
                        <TextField
                            id="term"
                            label="term"

                            variant="outlined"
                            type="text"
                            sx={{
                                boxShadow: 'none', input: {
                                    border: "none",
                                }, '.MuiOutlinedInput-notchedOutline': { border: 0 }
                            }}
                            name="term"
                            inputProps={{ style: { border: "3px solid #011633", borderRadius: "5px", color: '#011633' } }}
                            style={{ color: '#011633' }}
                            value={inputField.term}
                            onChange={inputsHandler}
                            fullWidth
                            autoComplete="name"
                        />
                    </div>
                    <div className="glossary-div">
                        <FormControl>
                            <InputLabel id="demo-simple-select-label" style={{ color: '#011633', background: '#FFF' }}>
                                Select Domain:
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                multiple={true}
                                name="domain"
                                id="domain"
                                sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: '4px' } }}
                                value={domain}
                                style={{ color: '#011633', border: "solid #011633" }}
                                onChange={inputsHandlerDomain}
                            >
                                <MenuItem value={'All'}>Select All</MenuItem>
                                <MenuItem value={'Administrative'}>Administrative</MenuItem>
                                <MenuItem value={'Agriculture'}>Agriculture</MenuItem>
                                <MenuItem value={'AgriTerm'}>Agri Term</MenuItem>
                                <MenuItem value={'Biology'}>Biology</MenuItem>
                                <MenuItem value={'Biotech'}>Biotech</MenuItem>
                                <MenuItem value={'Botany'}>Botany</MenuItem>
                                <MenuItem value={'Broadcasting'}>Broadcasting</MenuItem>
                                <MenuItem value={'CellBio'}>CellBio</MenuItem>
                                <MenuItem value={'Chemistry'}>Chemistry</MenuItem>
                                <MenuItem value={'ChemEng'}>Chemical Engineering</MenuItem>
                                <MenuItem value={'Civil'}>Civil</MenuItem>
                                <MenuItem value={'ClinicalEng'}>Clinical Engineering</MenuItem>
                                <MenuItem value={'Computer Science'}>Computer Science</MenuItem>
                                <MenuItem value={'Electrical'}>Electrical</MenuItem>
                                <MenuItem value={'Electronics'}>Electronics</MenuItem>
                                <MenuItem value={'Geography'}>Geography</MenuItem>
                                <MenuItem value={'History'}>History</MenuItem>
                                <MenuItem value={'IT'}>IT</MenuItem>
                                <MenuItem value={'Mathematics'}>Mathematics</MenuItem>
                                <MenuItem value={'MechEng'}>Mechanical Engineering</MenuItem>
                                <MenuItem value={'Mech'}>Mechanical</MenuItem>
                                <MenuItem value={'Medical'}>Medical</MenuItem>
                                <MenuItem value={'MedicalComp'}>Medical Comprehension</MenuItem>
                                <MenuItem value={'Microbio'}>Microbiology</MenuItem>
                                <MenuItem value={'Mineralogy'}>Mineralogy</MenuItem>
                                <MenuItem value={'MiningGeo'}>Mining Geography</MenuItem>
                                <MenuItem value={'Nematology'}>Nematology</MenuItem>
                                <MenuItem value={'OperationRes'}>Operation Research</MenuItem>
                                <MenuItem value={'Palaeontology'}>Palaeontology</MenuItem>
                                <MenuItem value={'Petrology'}>Petrology</MenuItem>
                                <MenuItem value={'Physics'}>Physics</MenuItem>
                                <MenuItem value={'PlasmaEngg'}>Plasma Engineering</MenuItem>
                                <MenuItem value={'PrintingEngg'}>Printing Engineering</MenuItem>
                                <MenuItem value={'Psychology'}>Psychology</MenuItem>
                                <MenuItem value={'QualityControl'}>Quality Control</MenuItem>
                                <MenuItem value={'Rbi'}>Rbi</MenuItem>
                                <MenuItem value={'SamajKarya'}>Samaj Karya</MenuItem>
                                <MenuItem value={'Sericulture'}>Sericulture</MenuItem>
                                <MenuItem value={'ShikshaParibhasha'}>Shiksha Paribhasha</MenuItem>
                                <MenuItem value={'Sociology'}>Sociology</MenuItem>
                                <MenuItem value={'SpaceSci'}>Space Science</MenuItem>
                                <MenuItem value={'Telecommunication'}>Telecommunication</MenuItem>
                                <MenuItem value={'Vanijya'}>Vanijya Paribhasha</MenuItem>
                                <MenuItem value={'WesternMusic'}>Western Music</MenuItem>
                                <MenuItem value={'Zoology'}>Zoology</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div style={{ marginTop: '2%', marginBottom: "4%", textAlign: "center", justifyContent: "center" }}>
                    <Button variant="contained" color="primary" onClick={submitButton}
                        size="large">
                        Submit
                    </Button>
                </div>
                <div className='glossTable' style={{ textAlign:"center",alignContent:"center" }}>
                    {inputField.csvData.length <= 0 ? (
                    <p></p>) : (<p style={{width:"100%", textAlign:"right",alignContent:"right"}}><Button variant="contained" color="primary"><CSVLink data={inputField.csvData} filename={"Udaan-glossary.csv"}>Download Table</CSVLink></Button></p>)}
                    <div id="GlossV" style={{ textAlign: "center",width:"100%",paddingLeft:"10%",paddingRight:"10%" }}></div>
                </div>
                <div><h3>If you are interested in downloading domain-specific glossaries, you can fill <a href="https://forms.gle/r55QLTVpZWNN3eaUA" target="_blank" className="color-link"> this form</a>.</h3></div>
            </div>
        </div>
    )
}
export default Glossaries