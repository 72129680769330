import React from "react";
import Footer from "../Footer/Footer";
import UDAANVideos from "./UDAANVideos";
// import RelatedProjects from "../Related Projects/RelatedProjects";

function UDAANVideosRelatedProjects() {
  return (
    <>
        <UDAANVideos />
        {/* <RelatedProjects /> */}
        <Footer />
    </>
  );
}

export default UDAANVideosRelatedProjects;
