import React from "react";

import "./UDAANVideos.css";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/components/navigation/navigation.scss";

import SwiperCore, { Navigation, Pagination } from "swiper";
SwiperCore.use([Navigation, Pagination]);

// import { useTranslation } from "react-i18next";
// import { Swiper, SwiperSlide } from "swiper/react";


function UDAANVideos() {
  // const { t } = useTranslation();
  return (
    <div className="video container" id="video">
      <p className="video_title">Videos</p>

      
      <div className="row">
        <div className="row">          
          <div className="video-container">
          <h4 style={{marginBottom: "0px"}} className="fadeInDown">
            <a href="https://www.youtube.com/playlist?list=PLfL0bubCSMdzHx117x6c4mdi47ZjkTz7H" target="_blank">1. Tutorial Videos - Full Playlist </a></h4>

            <h4 style={{marginBottom: "0px"}} className="fadeInDown">
            <a href="https://www.youtube.com/playlist?list=PLfL0bubCSMdxlsk92QaloGFPJfaWZbkhx" target="_blank">2. Online Training Sessions - Full Playlist </a></h4>
          <h4></h4>
          <h4></h4>
          <h4></h4>
          
          </div>
        </div>
      </div>
    </div>
  );
}

export default UDAANVideos;
